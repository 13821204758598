<template>
  <div class="smt">
    <topnav ref="navs" active="smt"></topnav>
    <topmenu active="smt"></topmenu>

    <div class="public_width">
        <!-- 轮播 -->
        <div class="carousel_box">
          <div class="home_carousel">
              <div v-if="banner.length > 0">
                  <el-carousel height="457px" ref="swiper" trigger="click" :interval="2500" :initial-index="0" >    
                      <el-carousel-item class="carouselItem" v-for="item in banner" :key="item.id">
                      <div class="url" @click="to_url(item)"  v-if="item.link_url">
                          <img :src="item.thumb"/>
                      </div>
                      <img :src="item.thumb" v-else/>
                      </el-carousel-item>
                  </el-carousel>
              </div>
          </div>
          <div class="smt_form">
              <div class="carousel_title">SMT计算价格</div>
              <el-form
                  :model="form"
                  :rules="rules"
                  ref="form_bom"
                  label-width="100px"
              >
                  <el-form-item label="贴片层:">
                    <el-radio-group v-model="form.smt_cover_type" >
                      <el-radio-button label="单面贴片"></el-radio-button>
                      <el-radio-button label="双面贴片"></el-radio-button> 
                    </el-radio-group>
                  </el-form-item>

                  <el-form-item label="单板生产数量:" >
                    <el-input v-model.number="form.veneer_num" placeholder="请输入" @input="number($event,'veneer_num')">
                      <template #append>pcs</template>
                    </el-input>
                  </el-form-item>

                  <el-form-item label="贴片:" >
                    <el-input class="short" v-model.number="form.smt_material_num" placeholder="请输入" @input="number($event,'smt_material_num')">
                      <template #append>种类/pcs</template>
                    </el-input>
                    <el-input class="short right" v-model.number="form.smt_welding_num" placeholder="请输入" @input="number($event,'smt_welding_num')">
                      <template #append>焊盘/pcs</template>
                    </el-input>
                  </el-form-item>

                  <el-form-item label="插件:">
                    <el-input class="short" v-model.number="form.dip_plug_material_num" placeholder="请输入" @input="number($event,'dip_plug_material_num')">
                      <template #append>种类/pcs</template>
                    </el-input>
                    <el-input class="short right" v-model.number="form.dip_plug_welding_num" placeholder="请输入" @input="number($event,'dip_plug_welding_num')">
                      <template #append>焊点/pcs</template>
                    </el-input>
                  </el-form-item>

              </el-form>

              <el-button class="form-btn" @click="to_flow()">在线计价</el-button>
          </div>
        </div>
        <!-- 下单流程 -->
        <div class="smt_flow">
          <img class="flow_img" src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202201/04/%E9%A3%9E%E4%B9%A620220105-095516.png"/>
        </div>

        <div class="smt_white">
            <!-- SMT产品展示 -->
            <div class="smt_goods">
              <div class="smt_title goods_title"><i class="iconfont icon-smtcpzs"></i>产品展示</div>
              <div class="goods_banner">
                <i class="iconfont icon-bg-left" @click="prev()"></i>
                <el-carousel height="230px" ref="product" trigger="click" :interval="2500" :initial-index="0" arrow="never">    
    
                  <el-carousel-item class="" v-for="item in manage" :key="item">
                    
                    <div class="manage_item" v-for="(v,k) in item" :key="k">
                      <a :href="v.url" target="_blank" v-if="v.url">
                        <img :src="v.img"/>
                      </a>
                      <img :src="v.img" v-else/>
                    </div>
                  </el-carousel-item>
                </el-carousel>
                <i class="iconfont icon-bg-right" @click="next()"></i>
              </div>
            </div>
            
            <!-- 工厂 -->
            <div class="smt_plant">
                <el-carousel class="smt_banner" height="540px" arrow="always">
                    <el-carousel-item v-for="item in smt_list" :key="item">
                        <img class="banner-img" :src="'https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/smt/' +item"/>
                    </el-carousel-item>
                </el-carousel>
                <div class="plant-info">
                    <img
                        class="symbol symbol-top"
                        src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/smt/yh2.png"
                        alt=""/>
                    <img
                        class="symbol symbol-bottom"
                        src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/smt/yh1.png"
                        alt=""/>
                    <h3 class="plant-title">工厂介绍</h3>
                    <p class="plant-intro">
                        南京致诺电子科技有限公司成立于2021年2月4日，以SMT贴片、模块化生产为主，现公司已正式投产运营。公司秉承质量第一，环保优先的政策，在质量方面采取了原料品管、制程品管、成品品管三道品管关卡，确保产品质量之一贯性。核心设备采用世界顶级厂家设备，两台日本YAMAHA贴片机（YSM10，YSM20）。其余设备也均为国内一线厂家，包括德森印刷机、劲拓回流炉、劲拓波峰焊、盟拓AIO。生产团队人员个人能力均为此行业一流水平，严格按照公司生产规范生产，使得产品质量达到一流的水平。
                    </p>
                    <h3 class="plant-title">联系方式</h3>
                    <div class="plant-rela">
                        <p>联系人：李经理</p>
                        <p>联系电话：15050595751</p>
                        <p>联系地址：南京市江宁滨江开发区地秀路757号B栋3楼</p>
                    </div>
                </div>
            </div>

            <!-- 服务 -->
            <div class="smt_serve">
                <ul class="d-between">
                    <li>
                        <img
                        src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/smt/icon_yzsfw.png"
                        alt="一站式服务"
                        />
                        <div>
                        <h5>一站式服务</h5>
                        <p>专业的IDH方案定制团队</p>
                        <p>从方案到产品的一站式服务</p>
                        </div>
                    </li>
                    <li>
                        <img
                        src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/smt/icon_pzbz.png"
                        alt="品质保证"
                        />
                        <div>
                        <h5>品质保证</h5>
                        <p>顶尖的技术团队</p>
                        <p>较为完善的原材料供应链</p>
                        </div>
                    </li>
                    <li>
                        <img
                        src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/smt/icon_sbbz.png"
                        alt="设备保证"
                        />
                        <div>
                        <h5>设备保证</h5>
                        <p>百万级无尘车间</p>
                        <p>全新的先进进口设备</p>
                        </div>
                    </li>
                    <li>
                        <img
                        src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/smt/icon_ksjf.png"
                        alt="快速交付"
                        />
                        <div>
                        <h5>快速交付</h5>
                        <p>SMT加急服务</p>
                        <p>24小时+24小时,PCBA加工完成</p>
                        </div>
                    </li>
                </ul>
            </div>

            <!-- 常见问题 -->
            <div class="smt_issue">
                <div class="issue_title"><i class="iconfont icon-icon-cjwt"></i>常见问题 <span @click="to_issue()">查看全部></span></div>
                <div class="issue_wrap d-center">
                    <ul class="issue_list">
                      <li> 
                        <img class="img" :src="title_icon">
                        <div class="issue_box">
                          <p class="ask">贵司能承接什么样类型的产品加工？</p>
                          <p class="answer">我司承接从小批量打样到大批量量产所有产品的加工。</p>
                        </div>
                      </li>
                      <li> 
                        <img class="img" :src="title_icon">
                        <div class="issue_box">
                          <p class="ask">贵司SMT是怎么收费的？</p>
                          <p class="answer">我们公司收费方式有两种，打样和小批量（少于1000pcs）我们是按物料种类和数量来计价。批量订单是点数来核算价格的。</p>
                        </div>
                      </li>
                      <li> 
                        <img class="img" :src="title_icon">
                        <div class="issue_box">
                          <p class="ask">贵司怎样加工打样生产的？</p>
                          <p class="answer">贴片部分我们全部是机器贴片，DIP插件我们是手工焊接。</p>
                        </div>
                      </li>
                    </ul>
                    <div class="line"></div>
                    <ul class="issue_list">
                      <li> 
                        <img class="img" :src="title_icon">
                        <div class="issue_box">
                          <p class="ask">贵司SMT加工需要哪些文件？</p>
                          <p class="answer">我司SMT是全机器加工，需要加工产品的钢网文件，PCB文件、BOM物料清单、位号丝印图、元件坐标。</p>
                        </div>
                      </li>
                      <li> 
                        <img class="img" :src="title_icon">
                        <div class="issue_box">
                          <p class="ask">贵司SMT能代购物料吗？</p>
                          <p class="answer">我司可以承接从PCB打板，BOM物料配单，SMT贴片加工及DIP插件焊接等所有业务。一站式为客户解决样品打样及量产的所有业务。</p>
                        </div>
                      </li>
                      <li> 
                        <img class="img" :src="title_icon">
                        <div class="issue_box">
                          <p class="ask">贵司SMT对物料和PCB有什么要求吗？</p>
                          <p class="answer">因为是机器贴片，PCB板尽量做成拼版，且加5mm工艺边。贴片物料包装为卷带或者托盘包装，数量加1%的损耗。</p>
                        </div>
                      </li>
                    </ul>
                </div>
            </div>

            <!-- 合作伙伴 -->
            <div class="smt_partner">
              <div class="smt_title partner_title"><i class="iconfont icon-icon-hzhb"></i>合作伙伴</div>
              <div class="partner_img d-center">
                <img src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202201/18/%E9%A3%9E%E4%B9%A620220119-171438.png">
              </div>
            </div> 
        </div>
    </div>
    <foot />
    <toolbar />
  </div>
</template>

<script>
import toolbar from "@/components/Toolbar.vue";
import topnav from "@/components/Nav.vue";
import topmenu from "@/components/Menu.vue";
import foot from "@/components/Footer.vue";
import { getData } from "@/api/user";
import { reactive,toRefs,ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
    setup() {
        const store = useStore()
        const router = useRouter();
        const product = ref(null)
        store.commit('setCount');

        let state = reactive({
            userid: store.state.userId,
            active: "smt",
            smt_list: [
                "gc_ab29256100d694de77ff17446e07a45.jpg",
                "gc_fc4938f30333676e357c98401302df0.jpg",
                "gc_3b77ef2a01eedeebfe556591cc9e8eb.jpg",
                "gc_img_gctp.png",
                "gc_2455c7186cb1c4c7a63012bcf86ffbb.jpg",
                "gc_eac7324bea6b945f5c9b69dd0002d15.jpg",   
                "gc_656cad9473b98a3acbf25efc57e098d.jpg",
                "gc_3b9ad017c6af64b8a1803292f2dfd34.jpg",
                "gc_244a8bb18cc827a5a8b8c95d1ae4a68.jpg",
            ],
            // banner
            form: {
                smt_cover_type:'单面贴片',
                veneer_num: '',
                smt_material_num:'',
                smt_welding_num:'',
                dip_plug_material_num:'',
                dip_plug_welding_num:'',
            },
            rules: {
                name: [
                {
                    required: true,
                    message: "请输入联系人",
                    trigger: "blur",
                },
                ],
            },
            // 标题图标
            title_icon:'https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202112/30/%E5%9B%BE%E7%89%87/cjwt-tb.png',
            
            // banner
            banner: [],

            // 产品展示
            manage:[
              [
                {
                  img:'https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202201/04/smt%E5%9B%BE%E7%89%87/%E7%91%9E%E8%8A%AF%E5%BE%AERK3568%20AI%E6%9D%BF%E5%8D%A1%20%20.png',
                  url:'https://www.ebaina.com/ai/26'
                },
                {
                  img:'https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202201/04/smt%E5%9B%BE%E7%89%87/%E6%B5%B7%E6%80%9D3519AV100%20AI%E6%9D%BF%E5%8D%A1%20%20.png',
                  url:'https://www.ebaina.com/ai/21'
                },
                {
                  img:'https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202201/04/smt%E5%9B%BE%E7%89%87/%E6%B5%B7%E6%80%9D3531DV200%20%E5%9B%BE%E5%83%8F%E5%A4%84%E7%90%86%E6%9D%BF%E5%8D%A1%20%20.png',
                  url:'https://www.ebaina.com/ai/20'
                },
                {
                  img:'https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202201/04/smt%E5%9B%BE%E7%89%87/%E7%91%9E%E8%8A%AF%E5%BE%AERK1808%20AI%E6%9D%BF%E5%8D%A1%20%20.png',
                  url:'https://www.ebaina.com/ai/16'
                },
                {
                  img:'https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202201/04/smt%E5%9B%BE%E7%89%87/%E6%B5%B7%E6%80%9D3516DV300%20AI%E6%9D%BF%E5%8D%A1%20%20.png',
                  url:'https://www.ebaina.com/ai/14'
                },
                
              ],
              [
                {
                  img:'https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202201/04/smt%E5%9B%BE%E7%89%87/%E7%91%9E%E8%8A%AF%E5%BE%AERK3399%20%E5%B7%A5%E4%B8%9A%E6%9D%BF%E5%8D%A1%20%20.png',
                  url:'https://www.ebaina.com/ai/12'
                },
                {
                  img:'https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202201/04/smt%E5%9B%BE%E7%89%87/%E7%91%9E%E8%8A%AF%E5%BE%AERK3288%E5%BC%80%E5%8F%91%E7%89%88%20.png',
                  url:'https://www.ebaina.com/ai/11'
                },
                {
                  img:'https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202201/04/smt%E5%9B%BE%E7%89%87/A201%E5%9E%8B%E5%8F%B7%20%E7%91%9E%E8%8A%AF%E5%BE%AERV1126%E5%BC%80%E5%8F%91%E6%9D%BF.png',
                  url:'https://www.ebaina.com/ai/10'
                },
                {
                  img:'https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202201/04/smt%E5%9B%BE%E7%89%87/%E7%91%9E%E8%8A%AF%E5%BE%AERV1109%20AI%E6%9D%BF%E5%8D%A1%20%20.png',
                  url:'https://www.ebaina.com/ai/13'
                },
                {
                  img:'https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202201/04/smt%E5%9B%BE%E7%89%87/A191%E5%9E%8B%E5%8F%B7%20RV1126%E5%BC%80%E5%8F%91%E6%9D%BF%EF%BC%8838%E6%9D%BF%EF%BC%89.png',
                  url:'https://www.ebaina.com/ai/22'
                }
              ],
            ]
        })

        let init = () => {
          getData({
                url: `api/smt/banner`,
                data: {},
            }).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    state.banner= data.data.banner
                }
            });
        }
        init()

        let number = (value,type) => {
          if(value.length == 1 && value==0){
            state.form[type] = ''
          }else{
            state.form[type] = value.replace(/[^0-9]/g,'');
          }
        }

        let prev = () => {
          product.value.prev()
        }

        let next = () => {
          product.value.next()
        }

        let to_issue = () => {
            router.push({
                path:'/smt_issue'
            })
            window.scrollTo(100,0)
        }

        let to_flow = () => {
          if(state.userid) {
            state.form.from = 'smt'
            router.push({
                path:'/smt_flow',
                query:state.form
            })
            window.scrollTo(100,0)
          }else {
            store.commit("setLoginBox", true);
          }
        }

        let to_url = (item) => {
          if(state.userid) {
            location.href=item.link_url
          }else {
            store.commit("setLoginBox", true);
          }
        }
        
        return {
            ...toRefs(state),
            to_issue,
            to_flow,
            prev,
            next,
            product,
            number,
            to_url
        };
    },
    components: {
        topnav,
        topmenu,
        foot,
        toolbar
    }
};
</script>

<style lang="less">
.smt {
    padding: 154px 0 440px;

    .url{
      cursor: pointer;
    }

    .carousel_box {
        margin-top: 14px;
        position: relative;
        height: 448px;
        width: 100%;
    }

    .smt_form {
        position: absolute;
        left: 52px;
        top: 52px;
        width: 535px;
        height: 344px;
        padding: 24px 58px;
        background: rgba(255, 255, 255, 0.7);
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        overflow:hidden;
        .carousel_title {
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
            color: #333333;
            margin-bottom: 25px;
        }

        .el-form-item {
          margin-bottom: 8px;
        }

         /*单选 */
        .el-radio-group  {
          float:left;
        }
        .el-radio-button {

          border-radius: 3px;
          background: #fff;
          .el-radio-button__inner {
            padding: 0 10px;
            width: 150px;
            height: 34px;
            border: 2px solid #D0D0D0;
            font-size: 13px;
            font-weight: 400;
            line-height: 34px;
            color: #333333;
            text-align:center;
            box-shadow: none;
            transition:none;
            background: #fff;
          }
        }
        .el-radio-button:first-child {
          margin-right:18px;
        }
        
        .el-radio-button.is-active {
          .el-radio-button__inner {
            border: 2px solid #316DFA;
            background:#fff url(https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202112/30/%E5%9B%BE%E7%89%87/icon-jg.png) no-repeat bottom right;
          }
        }
        .el-input {
          width: 319px;
          .el-input-group__append {
            height: 34px;
            width: 73px;
            padding: 0;
            text-align: center;
            border: 1px solid #D0D0D0;
            background: #f5f5f5;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            color: #666666;
            margin-left: -1px;
            border-left: none;
          }
        }
        .el-input__inner,.el-form-item__content,.el-form-item__label{
          height: 34px;
          line-height: 34px;
        }
        .el-input__inner {
          border: 1px solid #D0D0D0;
        }
        .el-form-item__label{
          text-align: left;
          position: relative;
        }
        .el-form-item__label:before {
          position:absolute;
          top:50%;
          left:-10px;
          transform: translateY(-50%);
        }
        .el-input.short{
          width: 150px;
        }
        .el-input.short.right{
          margin-left: 18px;
        }
        .el-form-item__content {
          position: relative;
        }
        .el-form-item__error {
          position: absolute;
          top: 0;
          left: 253px;
          height: 30px;
          line-height: 30px;
          padding: 0;
          width: 300px;
        }

        .form-btn,
        .form-btn:focus {
            width: 100%;
            height: 48px;
            background: #0064f9;
            border: none;
            border-radius: 4px;
            font-size: 16px;
            font-weight: 500;
            line-height: 48px;
            color: #FFFFFF;
            padding: 0;
            margin-top: 17px;
        }

        .form-btn:hover {
            color: #fff;
            background: #5a9cff;
        }
        .form-btn:active {
            color: #fff;
            background: #004ab7;
        }
    }
    .smt_form:before {
      content: '';
      position: absolute;
      top:0;right:0;bottom:0;left:0;
      -webkit-filter: blur(50px);
      -moz-filter: blur(50px);
      -ms-filter: blur(50px);
      -o-filter: blur(50px);
      filter: blur(50px);
      filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius=4, MakeShadow=false);
    }

    .smt_form::before{
        content: '';
        position: absolute;
        top: 0; bottom: 0;
        left: 0; right: 0;
        filter: blur(50px);
        z-index: -1;
        margin: -30px;
    }

    .bom-box {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .bom-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 260px;
        height: 60px;
        margin-right: 25px;
        line-height: 60px;
        background: #e3efff;
        border-radius: 8px;
        font-size: 18px;
        font-weight: 500;
        color: #4d90fb;
        img {
        width: 24px;
        margin-right: 10px;
        }
    }
    .bom-btn:hover {
        color: #4d90fb;
        background: #f4f8ff;
    }
    .bom-btn:active {
        color: #4d90fb;
        background: #a8cdff;
    }

    .bom-tip {
        width: 236px;
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        color: #999999;
        text-align: left;
    }



    .smt_white {
        padding: 20px 24px 80px;
        background: #fff;
    }

    .smt_title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 35px;
    }

    .smt_flow {
        height: 134px;
        width: 100%;
        margin-bottom: 20px;
        .flow_img {
          height: 100%;
          width: 100%;
        }
    }

    .goods_title {
      font-size: 24px;
      font-weight: 600;
      line-height: 33px;
      color: #333333;
      .iconfont {
        font-weight: 400;
        font-size: 28px;
        margin-right: 10px;
      }
    }

    .smt_goods {
      margin-bottom: 70px;
      padding: 0 35px;
      .manage_item {
        display:inline-block;
        width: 230px;
        margin:0 20px 0 0;
      }
      .manage_item:nth-child(5n) {
        margin:0;
      }
      .goods_banner {
        position:relative;
        .iconfont {
          font-size: 36px;
          width: 36px;
          height: 36px;
          color: rgba(94, 92, 92, 0.39);
          position:absolute;
          top:50%;
          transform: translateY(-50%);
          left:-45px;
          cursor:pointer;
        }
        .icon-bg-right {
          left: auto;
          right: -44px;
        }
      }
    }

    /* 服务 */
    .smt_serve {
        padding: 70px 37px;
        li {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                height: 98px;
                width: 98px;
                margin-right: 10px;
            }
            h5 {
                margin-bottom: 7px;
                font-size: 23px;
                font-weight: 600;
                line-height: 32px;
                color: #4d90fb;
            }
            p {
                font-size: 14px;
                font-weight: 400;
                line-height: 25px;
                color: #666;
            }
        }
    }

    /* 问题 */
    .smt_issue {
        padding: 20px 0 4px;
        background: #F0F6FF;
        border-radius: 5px;
        margin-bottom: 70px;
    }

    .issue_title {
      position:relative;
      font-size: 24px;
      font-weight: 600;
      line-height: 34px;
      color: #092C8B;
      text-align: center;
      margin-bottom: 45px;
      .iconfont {
        font-size: 22px;
        font-weight: 400;
        margin-right: 10px;
      }
      span {
        font-size: 13px;
        font-weight: 400;
        line-height: 34px;
        color: #092C8B;
        float: right;
        position: absolute;
        top: 0;
        right: 26px;
        cursor:pointer;
      }
    }

    .issue_wrap {
      padding: 0 52px 0 77px;

      .issue_list> li {
        display: flex;
        align-items: flex-start;
        margin-bottom: 30px;
        .img {
          height: 17px;
          width: 17px;
          margin:2px 14px 0 0;
        }
        .ask {
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          color: #333333;
          margin-bottom: 6px;
        }
        .answer {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: #666666;
          min-height: 40px;
        }
      }

      .line {
        width: 1px;
        height: 128px;
        background:#D3E7FE;
        margin: 0 120px 0 98px; 
      }
    }

    /* 工厂 */
    .smt_plant {
        position: relative;
        padding: 0 50px;
    
        .smt_banner {
            position: relative;
            width: 700px;
            border-radius: 12px;
            .el-carousel__arrow {
            display: none !important;
            }
            .el-carousel__indicators {
            left: 108px;
            bottom: 12px;
            .el-carousel__button {
                width: 10px;
                height: 10px;
                background: #fff;
                border-radius: 50%;
                opacity: 1;
            }
            }

            .el-carousel__indicator.is-active .el-carousel__button {
            width: 22px;
            height: 10px;
            background: #ff6c47;
            border-radius: 10px;
            }
        }
        .banner-img {
            height: 100%;
            width: 100%;
        }
        .plant-info {
            position: absolute;
            right: 50px;
            top: 36px;
            z-index: 2;
            padding: 30px 33px 60px 42px;
            width: 588px;
            height: 480px;
            background: #ffffff;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            border-radius: 1px;

            .plant-title {
            margin-bottom: 8px;
            font-size: 23px;
            font-weight: 600;
            line-height: 32px;
            color: #4d90fb;
            }
            p {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 30px;
            color: #333333;
            }
            .plant-intro {
            margin-bottom: 16px !important;
            }
            .plant-rela {
            margin-bottom: 10px !important;
            }
            .symbol {
            height: 16px;
            width: 18px;
            }
            .symbol-top {
            position: absolute;
            top: 25px;
            right: 24px;
            }
            .symbol-bottom {
            position: absolute;
            left: 33px;
            bottom: 24px;
            }
        }
    }

    /* 合作伙伴 */
    .smt_partner {

        .partner_title {
          font-size: 24px;
          font-weight: 600;
          line-height: 33px;
          color: #333333;
          margin-bottom: 21px;
          .iconfont {
            font-size: 22px;
            margin-right: 10px;
            font-weight: 400;
          }
        }
    }


    

}
.apply-dialog {
    .el-dialog {
        position: relative;
        height: auto;
    }
    .el-dialog__body {
        padding: 0 16px 36px 21px;
    }
    .el-dialog__footer {
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 10px 16px 29px 20px;
    }
    .dialog-title {
        margin-bottom: 18px;
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        color: #4d90fb;
        letter-spacing: 1px;
    }
    p {
        padding-left: 5px;
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        color: #999999;
    }
}

.write-dialog {
    .el-dialog {
        position: relative;
        height: auto;
    }
    .el-dialog__body {
        padding: 0 16px 36px 21px;
    }
    .el-dialog__footer {
        padding: 0 16px 29px 20px;
    }
    .dialog-title {
        margin-bottom: 18px;
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        color: #4d90fb;
        letter-spacing: 1px;
    }
    .el-form-item {
        width:100%;
        .el-form-item__content {
            width: calc(100% - 70px);
        }
    }
}

</style>
